<template>
  <section class="main-table-container">
    <div class="column is-12">
      <div class="field">
        <p class="control has-icons-left" style="max-width: 30rem">
          <input
            v-model="filter"
            class="input"
            type="text"
            placeholder="Search by name..."
          />
          <span class="icon is-small is-left">
            <icon :icon="['fas', 'search']" />
          </span>
        </p>
      </div>
    </div>
    <div v-if="loadingForms" class="loader-wrapper">
      <div class="loader is-loading" />
    </div>
    <div
      v-else-if="!forms.length"
      style="text-align: center; margin: 3rem auto"
    >
      <h1>No forms were found</h1>
    </div>
    <template v-else>
      <div class="column is-12">
        <div class="table-container">
          <table class="table is-striped is-fullwidth">
            <thead>
              <tr>
                <td>Form Title</td>
                <td>Description</td>
                <td>Company</td>
                <td>Owner</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="form in forms" v-bind:key="form._id">
                <td>{{ form.title }}</td>
                <td>{{ form.description || 'N/A' }}</td>
                <td>{{ form.company.name }}</td>
                <td>
                  {{
                    form && form.owner && form.owner.username
                      ? form.owner.username
                      : 'Unknown'
                  }}
                </td>
                <td class="actions">
                  <div class="field has-addons">
                    <p class="control">
                      <router-link
                        :to="hasPermissions(form) ? `/forms/${form._id}` : ''"
                        class="button tooltip is-tooltip-bottom preview"
                        data-tooltip="Create record"
                        :disabled="!hasPermissions(form)"
                        :style="{
                          'pointer-events': hasPermissions(form)
                            ? 'auto'
                            : 'none'
                        }"
                      >
                        <span class="icon">
                          <icon :icon="['fas', 'plus-circle']" />
                        </span>
                      </router-link>
                    </p>
                    <p class="control">
                      <router-link
                        :to="'/forms/' + form._id + '/records'"
                        class="button tooltip is-tooltip-bottom"
                        data-tooltip="View records"
                      >
                        <span class="icon">
                          <icon :icon="['fas', 'chart-bar']" />
                        </span>
                      </router-link>
                    </p>
                    <p class="control">
                      <router-link
                        :to="'/logs/form/' + form._id + '/'"
                        class="button tooltip is-tooltip-bottom"
                        data-tooltip="View logs"
                      >
                        <span class="icon">
                          <icon :icon="['fas', 'history']" />
                        </span>
                      </router-link>
                    </p>
                    <p class="control">
                      <a
                        :href="
                          hasPermissionsToEditAndDelete(form) ? `/builder/${form._id}` : ''
                        "
                        class="button tooltip is-tooltip-bottom"
                        data-tooltip="Edit form"
                        :disabled="!hasPermissionsToEditAndDelete(form)"
                        :style="{
                          'pointer-events': hasPermissionsToEditAndDelete(form)
                            ? 'auto'
                            : 'none'
                        }"
                      >
                        <span class="icon">
                          <icon :icon="['fas', 'pencil-alt']" />
                        </span>
                      </a>
                    </p>
                    <p class="control">
                      <a
                        class="button tooltip is-danger is-tooltip-bottom"
                        data-tooltip="Delete form"
                        @click="confirmFormRemoval(form)"
                        :disabled="!hasPermissionsToEditAndDelete(form)"
                        :style="{
                          'pointer-events': hasPermissionsToEditAndDelete(form)
                            ? 'auto'
                            : 'none'
                        }"
                      >
                        <span class="icon">
                          <icon :icon="['fas', 'trash-alt']" />
                        </span>
                      </a>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-12">
        <nav class="pagination is-small is-right">
          <a class="pagination-previous" @click="previousPage">Previous</a>
          <a class="pagination-next" @click="nextPage">Next page</a>
          <ul class="pagination-list">
            <li v-for="page in totalPages" :key="page">
              <a
                class="pagination-link"
                :class="{ 'is-current': page === currentPage }"
                @click="changePage(page)"
              >
                {{ page }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </template>
    <modal
      v-show="showModalConfirmation"
      @closeModal="closeModal"
      v-bind="modalConfig"
    />
  </section>
</template>

<script>
import axios from 'axios';
import { debounce } from '@/helpers/general';

export default {
  name: 'FormList',
  props: {
    targetList: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingForms: false,
      forms: [],
      user: $cookies.get($formsConfig.core.cookieName),
      showModalConfirmation: false,
      paginationOnSearch: false,
      formToDelete: null,
      currentPage: 0,
      totalPages: 0,
      filter: ``,
      debouncedFilter: ``,
      limit: 10,
      skip: 0,
      modalConfig: {
        bodyText: 'Are you sure you want to delete this form?',
        titleText: 'Delete Confirmation',
        buttons: {
          confirm: {
            text: 'Delete',
            class: 'is-danger'
          },
          reject: {
            text: 'Cancel',
            class: ''
          }
        }
      }
    };
  },
  beforeMount() {
    this.fetchForms();
  },
  watch: {
    filter: debounce(function (newVal) {
      this.debouncedFilter = newVal;
      this.fetchForms();
    }, 800),
    targetList(newVal) {
      if (newVal) {
        this.fetchForms({ reset: true });
      }
    }
  },
  methods: {
    changePage(page) {
      if (page !== this.currentPage) {
        this.skip = Math.ceil(page * this.limit - 10);
        this.fetchForms();
      }
    },
    nextPage() {
      if (this.currentPage !== this.totalPages) {
        this.skip += this.limit;
        this.fetchForms();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.skip -= this.limit;
        this.fetchForms();
      }
    },
    async fetchForms({ reset } = {}) {
      if (reset) {
        this.filter = ``;
        this.debouncedFilter = ``;
        this.skip = 0;
      }
      if (!this.debouncedFilter && this.paginationOnSearch) {
        // Means user delete filter text after a search, reset the skip to 0.
        this.skip = 0;
      }
      try {
        this.loadingForms = true;
        const response = await axios.get($formsConfig.core.api.forms, {
          params: {
            target: this.targetList,
            filter: this.debouncedFilter,
            limit: this.limit,
            skip:
              this.debouncedFilter && !this.paginationOnSearch ? 0 : this.skip
          }
        });
        this.forms = response.data.forms;
        this.currentPage = response.data.page;
        this.totalPages = response.data.pages;
        this.paginationOnSearch = response.data.paginationOnSearch;
      } catch (error) {
        this.$notify({
          group: 'alert',
          title: 'Something went wrong!',
          text: (error && error.message) || 'Error while fetching form list.',
          type: 'error'
        });
      } finally {
        this.loadingForms = false;
      }
    },
    async removeForm() {
      return await axios
        .delete($formsConfig.core.api.forms + this.formToDelete._id)
        .then(response => {
          if (response.status === 200) {
            const idx = this.forms.findIndex(
              form => form._id === this.formToDelete._id
            );
            this.forms.splice(idx, 1);
          }
        });
    },
    confirmFormRemoval(form) {
      if (!this.hasPermissions(form)) {
        return;
      }
      this.showModalConfirmation = true;
      this.formToDelete = form;
    },
    async closeModal(result) {
      if (result.remove) {
        await this.removeForm();
      }
      this.showModalConfirmation = false;
      this.formToDelete = null;
    },
    userInGroupCanEdit(form) {
      let userInGroup =
        (form &&
          form.permissions &&
          form.permissions.find(permission => {
            const user = permission && permission.group && permission.group.users && permission.group.users.find(user => {
              return this.user.id === user;
            });
            return typeof user !== 'undefined';
          })) ||
        null;
      return (userInGroup && userInGroup.canEdit) || false;
    },
    // A user can edit if is admin / owner of the document or if he has edit access through the form.
    // A user can see the records of a form if is admin / owner or if he has read access through the form
    hasPermissionsToEditAndDelete(form) {
      return (
        this.user.role === 'admin' ||
        this.user.id === (form && form.owner && form.owner.id)
      );
    },
    // A user can see the records of a form if is admin / owner or if he has read access through the form
    hasPermissions(form) {
      return (
        this.user.role === 'admin' ||
        this.user.id === (form && form.owner && form.owner.id) ||
        this.userInGroupCanEdit(form)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.main-table-container {
  .table-container {
    min-height: 55vh;
    max-height: 55vh;
    overflow: auto;
  }
  .pagination-link.is-current {
    background-color: #3e4a60;
    border-color: #3e4a60;
    color: #fff;
  }
}
</style>
