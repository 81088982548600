var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"main-table-container"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control has-icons-left",staticStyle:{"max-width":"30rem"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"input",attrs:{"type":"text","placeholder":"Search by name..."},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}}),_c('span',{staticClass:"icon is-small is-left"},[_c('icon',{attrs:{"icon":['fas', 'search']}})],1)])])]),(_vm.loadingForms)?_c('div',{staticClass:"loader-wrapper"},[_c('div',{staticClass:"loader is-loading"})]):(!_vm.forms.length)?_c('div',{staticStyle:{"text-align":"center","margin":"3rem auto"}},[_c('h1',[_vm._v("No forms were found")])]):[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table is-striped is-fullwidth"},[_vm._m(0),_c('tbody',_vm._l((_vm.forms),function(form){return _c('tr',{key:form._id},[_c('td',[_vm._v(_vm._s(form.title))]),_c('td',[_vm._v(_vm._s(form.description || 'N/A'))]),_c('td',[_vm._v(_vm._s(form.company.name))]),_c('td',[_vm._v(" "+_vm._s(form && form.owner && form.owner.username ? form.owner.username : 'Unknown')+" ")]),_c('td',{staticClass:"actions"},[_c('div',{staticClass:"field has-addons"},[_c('p',{staticClass:"control"},[_c('router-link',{staticClass:"button tooltip is-tooltip-bottom preview",style:({
                        'pointer-events': _vm.hasPermissions(form)
                          ? 'auto'
                          : 'none'
                      }),attrs:{"to":_vm.hasPermissions(form) ? ("/forms/" + (form._id)) : '',"data-tooltip":"Create record","disabled":!_vm.hasPermissions(form)}},[_c('span',{staticClass:"icon"},[_c('icon',{attrs:{"icon":['fas', 'plus-circle']}})],1)])],1),_c('p',{staticClass:"control"},[_c('router-link',{staticClass:"button tooltip is-tooltip-bottom",attrs:{"to":'/forms/' + form._id + '/records',"data-tooltip":"View records"}},[_c('span',{staticClass:"icon"},[_c('icon',{attrs:{"icon":['fas', 'chart-bar']}})],1)])],1),_c('p',{staticClass:"control"},[_c('router-link',{staticClass:"button tooltip is-tooltip-bottom",attrs:{"to":'/logs/form/' + form._id + '/',"data-tooltip":"View logs"}},[_c('span',{staticClass:"icon"},[_c('icon',{attrs:{"icon":['fas', 'history']}})],1)])],1),_c('p',{staticClass:"control"},[_c('a',{staticClass:"button tooltip is-tooltip-bottom",style:({
                        'pointer-events': _vm.hasPermissionsToEditAndDelete(form)
                          ? 'auto'
                          : 'none'
                      }),attrs:{"href":_vm.hasPermissionsToEditAndDelete(form) ? ("/builder/" + (form._id)) : '',"data-tooltip":"Edit form","disabled":!_vm.hasPermissionsToEditAndDelete(form)}},[_c('span',{staticClass:"icon"},[_c('icon',{attrs:{"icon":['fas', 'pencil-alt']}})],1)])]),_c('p',{staticClass:"control"},[_c('a',{staticClass:"button tooltip is-danger is-tooltip-bottom",style:({
                        'pointer-events': _vm.hasPermissionsToEditAndDelete(form)
                          ? 'auto'
                          : 'none'
                      }),attrs:{"data-tooltip":"Delete form","disabled":!_vm.hasPermissionsToEditAndDelete(form)},on:{"click":function($event){return _vm.confirmFormRemoval(form)}}},[_c('span',{staticClass:"icon"},[_c('icon',{attrs:{"icon":['fas', 'trash-alt']}})],1)])])])])])}),0)])])]),_c('div',{staticClass:"column is-12"},[_c('nav',{staticClass:"pagination is-small is-right"},[_c('a',{staticClass:"pagination-previous",on:{"click":_vm.previousPage}},[_vm._v("Previous")]),_c('a',{staticClass:"pagination-next",on:{"click":_vm.nextPage}},[_vm._v("Next page")]),_c('ul',{staticClass:"pagination-list"},_vm._l((_vm.totalPages),function(page){return _c('li',{key:page},[_c('a',{staticClass:"pagination-link",class:{ 'is-current': page === _vm.currentPage },on:{"click":function($event){return _vm.changePage(page)}}},[_vm._v(" "+_vm._s(page)+" ")])])}),0)])])],_c('modal',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showModalConfirmation),expression:"showModalConfirmation"}],on:{"closeModal":_vm.closeModal}},'modal',_vm.modalConfig,false))],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('td',[_vm._v("Form Title")]),_c('td',[_vm._v("Description")]),_c('td',[_vm._v("Company")]),_c('td',[_vm._v("Owner")]),_c('td',[_vm._v("Actions")])])])}]

export { render, staticRenderFns }